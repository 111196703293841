var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import dayjs from 'dayjs';
import { OPCOS } from '@hip/interfaces';
var getFormater = function (opco) {
    switch (opco) {
        case OPCOS.BQUK:
            return 'h:mm a';
        default:
            return 'HH:mm';
    }
};
export var formatTimeAvailabilityV5 = function (timeAvailability, opco) {
    if (!timeAvailability) {
        return [];
    }
    return timeAvailability.map(function (timeSlot) { return ({
        person_id: 0,
        name: '',
        event_id: 0,
        resource_id: 0,
        time: 120,
        date: dayjs(timeSlot.start).format('YYYY-MM-DD'),
        datetime: timeSlot.start,
        formatedDatetime: dayjs(timeSlot.start).format(getFormater(opco)),
    }); });
};
// gets in format "0324" need to be converted to "2024-01-03"
export var formatDateForDateRequest = function (date) {
    //year-month 0324 -> formatted to year-month-day
    var rawDate = "20" + date.substring(2, 4) + "-" + date.substring(0, 2) + "-01";
    return dayjs(rawDate).format('YYYY-MM-DD');
};
export var formatTimeAvailability = function (timeAvailability, opco) {
    if (!timeAvailability) {
        return [];
    }
    return timeAvailability.map(function (timeSlot) { return (__assign(__assign({}, timeSlot), { formatedDatetime: dayjs(timeSlot.datetime).format(getFormater(opco)) })); });
};
export var collectUnavailableDates = function (dates) {
    return dates
        ? dates
            .filter(function (item) { return !item.spaces || item.fully_booked; })
            .map(function (item) { return item.date; })
        : [];
};
