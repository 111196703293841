import { jrniAPI } from '../../api/jrniAPI';
export var createBasketV5 = function (companyId) {
    return jrniAPI.post("/baskets", {
        company_id: companyId,
    });
};
export var addTimeslotToBasketV5 = function (basketId, serviceId, start, authToken, companyId) {
    return jrniAPI.post("/baskets/" + basketId + "/service_items", {
        service_id: serviceId,
        start: start,
        company_id: companyId,
    }, {
        headers: {
            'Auth-Token': authToken,
        },
    });
};
export var addTimeslotToBasket = function (storeId, storeServiceId, date, time, eventId) {
    return jrniAPI.post("/" + storeId + "/basket/add_item?service_id=" + storeServiceId, {
        entire_basket: true,
        service_id: storeServiceId,
        date: date,
        time: time,
        event_id: eventId,
    });
};
export var deleteTimeslotInBasket = function (storeId, authToken) {
    return jrniAPI.delete("/" + storeId + "/basket", {
        headers: { 'auth-token': authToken },
    });
};
export var deleteBasketV5 = function (basketId, authToken) {
    return jrniAPI.delete("/baskets/" + basketId, {
        headers: { 'auth-token': authToken },
    });
};
