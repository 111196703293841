import { jrniAPI } from '../../api/jrniAPI';
export var createJrniClient = function (storeId, basketToken, payload) {
    jrniAPI.defaults.headers['Auth-Token'] = basketToken;
    return jrniAPI.post("/" + storeId + "/client", payload);
};
export var checkoutJrniBasket = function (storeId, basketToken, payload) {
    jrniAPI.defaults.headers['Auth-Token'] = basketToken;
    return jrniAPI.post(storeId + "/basket/checkout", payload);
};
export var checkoutJrniBasketV5 = function (clientId, basketToken, basketId) {
    jrniAPI.defaults.headers['Auth-Token'] = basketToken;
    return jrniAPI.post("/baskets/" + basketId + "/checkout", {
        client: {
            id: clientId,
        },
    });
};
