var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import dayjs from 'dayjs';
export var getAvailableTimeSlotsV5 = function (timeAvailability, minAdvancePeriod) {
    return timeAvailability.filter(function (timeSlot) {
        return dayjs(timeSlot.start).isAfter(dayjs().add(minAdvancePeriod, 'seconds'));
    });
};
export var getAvailableTimeSlots = function (timeAvailabilityResponse, minAdvancePeriod) {
    var mappedTimeslots = timeAvailabilityResponse._embedded.events.flatMap(function (event) {
        return event.times
            .filter(function (timeSlot) {
            return timeSlot.avail > 0 &&
                dayjs(timeSlot.datetime).isAfter(dayjs().add(minAdvancePeriod, 'seconds'));
        })
            .map(function (timeSlot) { return (__assign(__assign({}, timeSlot), { event_id: event.event_id, name: event.name, resource_id: event.resource_id, date: event.date, person_id: event.person_id })); });
    });
    var uniqueTimeValues = new Set(mappedTimeslots.map(function (timeSlot) { return timeSlot.time; }));
    var timeSlots = [];
    uniqueTimeValues.forEach(function (time) {
        timeSlots.push(mappedTimeslots.find(function (event) { return event.time === time; }));
    });
    var sortedTimeSlots = timeSlots.sort(function (a, b) { return Math.sign(a.time - b.time); });
    return sortedTimeSlots;
};
