export var processAddToBasketResponse = function (response) {
    var _a, _b;
    var items = (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a._embedded) === null || _b === void 0 ? void 0 : _b.items;
    var data = items && items[0];
    return {
        authToken: response.headers['auth-token'],
        eventId: data === null || data === void 0 ? void 0 : data.event_id,
        id: data === null || data === void 0 ? void 0 : data.id,
    };
};
