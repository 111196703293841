import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as features } from '@hip/feature-toggles';
import { basket } from './basket';
import { categories } from './categories';
import { location } from './location';
import { createTimeslotReducers } from './timeslot';
import { personalDetails } from './personalDetails';
import { checkout } from './checkout';
import { appointment } from './appointment';
import { analytics } from './analytics';
import { utils } from './utils';
export var createRootReducers = function (history) {
    return combineReducers({
        basket: basket,
        categories: categories,
        location: location,
        timeslot: createTimeslotReducers(),
        personalDetails: personalDetails,
        checkout: checkout,
        appointment: appointment,
        analytics: analytics,
        utils: utils,
        router: connectRouter(history),
        features: features,
    });
};
